<template>
  <div class="box">
    <!-- 标题 -->
    <van-cell
      :title="Detail && Detail.course ? Detail.course.info : ''"
      size="large"
      title-style="text-align: left;"
    />

    <!-- 视频 -->

    <div>
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
      >
      </video-player>
    </div>

    <!-- 切换tab -->
    <van-tabs v-model="active" color="#0dac85" swipeable>
      <van-tab
        :title="`课程选集(${Detail && Detail.list ? Detail.list.length : 0})`"
      >
        <div class="set">
          <div
            class="list flex"
            v-for="(i, index) in Detail.list"
            :key="i.id"
            :class="open == i.id ? 'act' : ''"
            @click="opens(index)"
          >
            <div class="img">
              <van-image
                fit="cover"
                :src="i.imgSrc"
                class="s"
                mode="aspectFill"
              />
            </div>
            <div class="text">
              <li>
                <span v-if="open == i.id"></span>
                {{ i.title }}
              </li>
              <p class="flex">
                <span>
                  <van-icon name="eye-o" />
                  {{ i.view }}
                </span>
                <span>
                  <van-icon name="clock-o" />
                  {{ i.updated_at }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="章节介绍">
        <div class="Introduction" v-html="strings"></div>
      </van-tab>
    </van-tabs>

    <div class="foote flex" id="foote">
      <li class="flex" @click="onLick(1)">
        <van-icon
          size="20"
          :name="!Detail.up ? 'good-job-o' : 'good-job'"
          :color="!Detail.up ? '#ccc' : '#d54b44'"
        />
        <p>点赞</p>
      </li>
      <li class="flex" @click="onStok(2)">
        <van-icon
          size="20"
          :name="!Detail.collect ? 'like-o' : 'like'"
          :color="!Detail.collect ? '#ccc' : '#d54b44'"
        />
        <p>收藏</p>
      </li>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import {
  Cell,
  CellGroup,
  Tab,
  Tabs,
  Icon,
  DropdownItem,
  Sticky,
  Empty,
  Toast,
  Dialog,
  Image as VanImage,
} from "vant";
export default {
  data() {
    return {
      // 新的
      open: null, // 第几个在播放  用id
      Detail: {},
      active: 0, // tab
      strings: null, // 简介

      // 视频播放配置
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "加载中", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  // components: { noData },
  created() {
    // http://localhost:8080/about?uid=5&kid=1
    const { uid, kid } = this.$route.query;
    this.postDetail({ uid, sorts_kid: kid, page: 1 });
  },

  components: {
    // eslint-disable-next-line vue/no-unused-components
    videoPlayer, // 视频
    [Cell.name]: Cell, // 单元格组
    [CellGroup.name]: CellGroup, // 单元格
    [Tab.name]: Tab, // tab组
    [Tabs.name]: Tabs, // tab
    [Icon.name]: Icon, // 图标
    [DropdownItem.name]: DropdownItem, // 下拉框
    [Sticky.name]: Sticky, // 吸顶容器
    [Empty.name]: Empty, // 无数据
    [Toast.name]: Toast, // 文字提示
    [Dialog.name]: Dialog, // 确认按钮提示
    [VanImage.name]: VanImage, // 图片
  },
  methods: {
    // 播放回调
    onPlayerPlay() {
      const { uid, kid } = this.$route.query;
      // 推一个播放量
      this.$request(2, { id: uid, kid }).then(() => {});
    },

    // 获取信息
    postDetail(e) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$request(1, e)
        .then((res) => {
          this.Detail = res;
          let { list } = res;
          let { vid } = this.$route.query;
          if (vid) {
            var index = list.findIndex((item) => item.id == vid);
            // 视频地址
            this.$set(
              this.playerOptions.sources[0],
              "src",
              res.list[index].videoSrc
            );
            // 视频缩略图
            this.$set(this.playerOptions, "poster", res.list[index].imgSrc);
            this.strings = res.list[index].introduce;
            this.open = vid;
          } else {
            this.$set(
              this.playerOptions.sources[0],
              "src",
              res.list[0].videoSrc
            );
            // 视频缩略图
            this.$set(this.playerOptions, "poster", res.list[0].imgSrc);
            this.strings = res.list[0].introduce;
            this.open = res.list[0].id;
          }
        })
        .catch((message) => {
          Dialog.alert({
            message,
            confirmButtonColor: "#1989fa",
          }).then(() => {});
        })
        .finally(() => {
          Toast.clear();
        });
    },

    //点赞
    onLick(e) {
      const { uid, kid } = this.$route.query;
      let obj = {
        uid,
        kid,
        type: parseInt(e),
        state: this.Detail.up ? 1 : 0,
      };
      this.$request(3, obj).then(() => {
        this.Detail.up ? "" : Toast.success("点赞成功");
        this.$set(this.Detail, "up", this.Detail.up ? false : true);
      });
    },
    //收藏
    onStok(e) {
      const { uid, kid } = this.$route.query;
      let obj = {
        uid,
        kid,
        type: parseInt(e),
        state: this.Detail.collect ? 1 : 0,
      };
      this.$request(3, obj).then(() => {
        this.Detail.collect ? "" : Toast.success("收藏成功");
        this.$set(this.Detail, "collect", this.Detail.collect ? false : true);
      });
    },

    // 播放第几个
    opens(e) {
      let { list } = this.Detail;

      this.$set(this.playerOptions.sources[0], "src", list[e].videoSrc);

      this.$set(this.playerOptions, "poster", list[e].imgSrc);

      this.strings = list[e].introduce;
      this.open = list[e].id;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .vjs-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.box {
  padding-bottom: 110px;
}
// 简介
.Introduction {
  padding: 20px;
  font-size: 28px;
  text-indent: 1em;
  line-height: 50px;
  color: #555;
}

// 集
.set {
  padding: 20px;
  .list {
    padding: 15px 0;
    border-bottom: 1px solid #e9e9e9;
    &:nth-last-child(1) {
      border-bottom: 0;
    }
    &.act {
      .img {
        image {
          border: 2px solid #0dac85;
        }
      }
      .text {
        li {
          color: #0dac85;
        }
      }
    }
    .img {
      width: 240px;
      image {
        width: 240px;
        height: 160px;
        display: block;
        border: 2px solid #fff;
      }
    }
    .text {
      width: 450px;
      li {
        font-size: 28px;
        line-height: 35px;
        height: 94px;

        // 播放动画
        span {
          margin: 0 20px;
          display: inline-block;
          width: 4px;
          height: 26px;
          background: #0dac85;
          vertical-align: middle;
          animation: stretchdelay 1s infinite linear;
          position: relative;
          &::after,
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 4px;
            height: 26px;
            background: #0dac85;
            top: 0;
            animation: stretchdelay 1s infinite linear;
          }
          &::after {
            left: -10px;
            animation-delay: -0.2s;
          }
          &::before {
            right: -10px;
            animation-delay: -0.4s;
          }
        }
      }
      p {
        line-height: 50px;
        text {
          vertical-align: middle;
        }
        span {
          font-size: 26px;
          color: #999;
          display: inline-block;
          margin-left: 6px;
          vertical-align: middle;
        }
      }
    }
  }
}
// 底部
.foote {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 25px;
  border-top: 1px solid #f0f0f0;
  li {
    width: 50%;
    justify-content: center;
    text-align: center;
    p {
      margin-left: 4px;
      font-size: 28px;
      color: #222;
      line-height: 48px;
      margin-top: -4px;
    }
  }
}

@keyframes stretchdelay {
  0%,
  100% {
    transform: scaleY(0.4);
  }
  50% {
    transform: scaleY(1);
  }
}
</style>
